import { makeAttachedTippyOptions, makeCenteredTippy } from './tippy-popper-options';
import { isString, isUndefined } from './type-check';
import tippy from 'tippy.js';

/**
 * Ensure class prefix ends in `-`
 * @param {string} prefix The prefix to prepend to the class names generated by nano-css
 * @return {string} The prefix ending in `-`
 */
export function normalizePrefix(prefix) {
  if (!isString(prefix) || prefix === '') {
    return '';
  }

  return prefix.charAt(prefix.length - 1) !== '-' ? `${prefix}-` : prefix;
}

/**
 * Checks if options.attachTo.element is a string, and if so, tries to find the element
 * @param {Step} step The step instance
 * @returns {{element, on}}
 * `element` is a qualified HTML Element
 * `on` is a string position value
 */
export function parseAttachTo(step) {
  const options = step.options.attachTo || {};
  const returnOpts = Object.assign({}, options);

  if (isString(options.element)) {
    // Can't override the element in user opts reference because we can't
    // guarantee that the element will exist in the future.
    try {
      returnOpts.element = document.querySelector(options.element);
    } catch(e) {
      // TODO
    }
    if (!returnOpts.element) {
      console.error(`The element for this Shepherd step was not found ${options.element}`);
    }
  }

  return returnOpts;
}

/**
 * Determines options for the tooltip and initializes
 * `step.tooltip` as a Tippy.js instance.
 * @param {Step} step The step instance
 */
export function setupTooltip(step) {
  if (isUndefined(tippy)) {
    throw new Error('Using the attachment feature of Shepherd requires the Tippy.js library');
  }

  if (step.tooltip) {
    step.tooltip.destroy();
  }

  const attachToOpts = parseAttachTo(step);

  step.tooltip = _makeTippyInstance(attachToOpts, step);

  step.target = attachToOpts.element;
}

/**
 * Generates a `Tippy` instance from a set of base `attachTo` options
 * @param attachToOptions
 * @param {Step} step The step instance
 * @return {tippy|Instance | Instance[]} The final tippy instance
 * @private
 */
function _makeTippyInstance(attachToOptions, step) {
  let tippyOptions = {};
  let element = document.body;

  if (!attachToOptions.element || !attachToOptions.on) {
    tippyOptions = makeCenteredTippy(step);
  } else {
    tippyOptions = makeAttachedTippyOptions(attachToOptions, step);
    element = attachToOptions.element;
  }

  return tippy(element, tippyOptions);
}

